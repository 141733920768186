import Topbar from "components/Topbar";
import About from "domain/About";
import Contact from "domain/Contact";
import BackStage from "domain/BackStage";
import Home from "domain/Home";
import Movie from "domain/Movie";
import Search from "domain/Search";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TVC from "domain/TVC";
import Documentary from "domain/Documentary";

function App() {
	return (
		<div className="w-screen h-screen">
			<Router>
				<Topbar />
				<Switch>
					<div className="flex w-full justify-center items-center">
						<div className="w-full lg:w-5/6 pb-16">
							<Route exact path="/" component={Home} />
							<Route exact path="/search/:searchId" component={Search} />
							<Route exact path="/backstage" component={BackStage} />
							<Route exact path="/works/tvc" component={TVC} />
							<Route exact path="/works/documentary" component={Documentary} />
							<Route exact path="/about" component={About} />
							<Route exact path="/contact" component={Contact} />
							<Route exact path="/movie/:movieId" component={Movie} />
						</div>
					</div>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
