import React from 'react'
import { AllInclusive, Memory, Camera, PhotoCamera } from "@material-ui/icons";
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import CameraRollIcon from '@mui/icons-material/CameraRoll';
import PermCameraMicIcon from '@mui/icons-material/PermCameraMic';

export default function About() {
    return (
        <div className="flex flex-col justify-center items-center mt-2 mb-32">
            <div className="w-5/6 md:w-4/6">
                <div className="flex flex-col justify-center items-center">
                    <div className="items-center justify-center">
                        <div className="w-12 h-12 bg-atl_green rounded-full items-center justify-center">
                            <PhotoCameraFrontIcon className="relative m-3" style={{fill: "white"}} />
                        </div>
                    </div>
                    <p className="text-justify m-4">
                        ATL Production is a company that provides professional production services on a broad scale, and has appended its signature under many projects both at home and abroad. The nice and pleasant experiences gained through collaborations, which the company established up to the present day with strong commitment to professionalism and sincerity, make up the very essence of ATL Production.
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center mt-4">
                    <div className="items-center justify-center">
                        <div className="w-12 h-12 bg-atl_green rounded-full items-center justify-center">
                            <CameraRollIcon className="relative m-3" style={{fill: "white"}} />
                        </div>
                    </div>
                    <p className="text-justify m-4">
                        Best quality has always been the primary target of our activities carried out with top agents and companies of this industry. With a team having created several successful productions in various branches, ATL Production is at your service with its offices in Ankara and Istanbul.
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center mt-4">
                    <div className="items-center justify-center">
                        <div className="w-12 h-12 bg-atl_green rounded-full items-center justify-center">
                            <PermCameraMicIcon className="relative m-3" style={{fill: "white"}} />
                        </div>
                    </div>
                    <p className="text-justify m-4">
                        “With each new project we experience the same excitement as waking up to a new day.”
                    </p>
                </div>
            </div>
        </div>
    )
}
