import React from 'react'
import useWindowDimensions from 'hooks/useWindowDimensions';
import chunkArray from 'util/functions';
import MovieCard from './MovieCard';
import { useParams } from 'react-router-dom'
import { MOVIE_DATA } from "util/data"

export default function Search() {
    let { searchId } = useParams();
    const { height, width } = useWindowDimensions();
    const [columnCount, setColumnCount] = React.useState(2)
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        if (width < 768) {
            setColumnCount(1)
        } else if (width < 1024) {
            setColumnCount(2)
        } else {
            setColumnCount(3)
        }
    }, [width])

    React.useEffect(() => {
        console.log(searchId);
        let filter = MOVIE_DATA.filter(m => {
            return m.movieName.toLocaleUpperCase('tr-TR').includes(searchId.toLocaleUpperCase('tr-TR'))
                || m.client.toLocaleUpperCase('tr-TR').includes(searchId.toLocaleUpperCase('tr-TR'))
                || m.directorName.toLocaleUpperCase('tr-TR').includes(searchId.toLocaleUpperCase('tr-TR'))
                || m.productionCompany.toLocaleUpperCase('tr-TR').includes(searchId.toLocaleUpperCase('tr-TR'))
        })
        setData(filter)
        console.log(filter);
    }, [])

    let result = chunkArray(data, columnCount)
    return (
        <div>
            <div className="flex mt-4 ml-8 mb-4">
                <div className="text-2xl font-bold mr-4">
                    Arama Sonuçları:
                </div>
                <div className="text-2xl">
                    {searchId}
                </div>
            </div>
            <div className="flex w-full justify-center items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 flex-1 px-4">
                    {
                        result.map((data) => {
                            return (
                                <div className="flex flex-col flex-grow">
                                    {
                                        data.map((movie) => {
                                            return (<MovieCard movie={movie} />)
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
