import React from 'react'
import { Twitter, Instagram, YouTube } from "@material-ui/icons";
import { IconButton, Tooltip } from '@material-ui/core';
import { Icon } from '@iconify/react';

export default function Contact() {
    return (
        <div className="flex flex-col flex-grow mx-8 mt-2 mb-24 justify-center items-center">
            <div className="text-gray-500 flex text-xl font-semibold justify-center">
                CONTACT US
            </div>
            <div className="flex flex-col space-y-2 mt-6">
                <div className="mb-8">
                    <p className="text-md text-center mb-2">
                        Kızılcaşar Mahallesi İncek Bulvarı Küme Evleri İncek Vista Plaza
                        No: 406 Daire: 263 Gölbaşı/Ankara
                    </p>
                    <div className="flex justify-center items-center space-x-8">
                        <a className="text-md font-semibold underline" href="tel:+90-539-591-5151">+90 (539) 591 5151</a>
                        <a className="font-semibold underline" href="mailto:">info@atlyapim.com</a>
                    </div>
                </div>
                <div className="w-full h-48 px-8">
                    <iframe
                        className="h-full w-full"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1278.9693906468249!2d32.734047299257796!3d39.83027018757094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d340960e1fe055%3A0xcd6bcc5f361ab4d2!2sVista%20Rezidans!5e0!3m2!1str!2str!4v1637082701033!5m2!1str!2str"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                    />
                </div>
            </div>
            <div className="flex flex-col space-y-2">
                <div className="text-gray-500 text-xl font-semibold mt-10 flex justify-center">
                    SOCIAL MEDIA
                </div>
                <a className="flex justify-center" href="https://vimeo.com/user156960506" target="_blank" rel="noreferrer" >
                    <div className="flex justify-start items-center">
                        <Tooltip title="Vimeo">
                            <IconButton >
                                <Icon icon="mdi:vimeo" className="w-6 h-6 cursor-pointer" />
                            </IconButton>
                        </Tooltip>
                        Vimeo
                    </div>
                </a>
                <a className="flex justify-center" href="https://www.youtube.com/channel/UC6rfOPabqlujxluxdctGU8Q" target="_blank" rel="noreferrer">
                    <div className="flex justify-start items-center">
                        <Tooltip title="YouTube">
                            <IconButton >
                                <YouTube className="w-8 h-8 cursor-pointer" />
                            </IconButton>
                        </Tooltip>
                        YouTube
                    </div>
                </a>
                <a className="flex justify-center" href="https://www.instagram.com/atl.yapim/" target="_blank" rel="noreferrer" >
                    <div className="flex justify-start items-center">
                        <Tooltip title="Instagram">
                            <IconButton >
                                <Instagram className="w-8 h-8 cursor-pointer" />
                            </IconButton>
                        </Tooltip>
                        Instagram
                    </div>
                </a>
                <a className="flex justify-center" href="https://twitter.com/atlyapim2021" target="_blank" rel="noreferrer">
                    <div className="flex justify-start items-center">
                        <Tooltip title="Twitter">
                            <IconButton >
                                <Twitter className="w-8 h-8 cursor-pointer" />
                            </IconButton>
                        </Tooltip>
                        Twitter
                    </div>
                </a>
            </div>
        </div>
    )
}
