import React from 'react'
import { NavLink } from 'react-router-dom'
import { Twitter, Instagram, Menu, Search, YouTube } from "@material-ui/icons";
import { IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { Transition } from '@windmill/react-ui'
import { Icon } from '@iconify/react';
import { TextField } from '@mui/material';
import { useHistory } from 'react-router-dom'

export default function Topbar() {
    const [open, setOpen] = React.useState(false)
    const [openWorks, setOpenWorks] = React.useState(false)
    const [search, setSearch] = React.useState("");
    const history = useHistory()

    const handleChange = (event) => {
        setSearch(event.target.value);
    };

    const onEnter = (e) => {
        if (e.keyCode == 13) {
            let search = e.target.value
            if (search === "") return
            history.push(`/search/${e.target.value}`)
            setSearch("");
        }
    }

    return (
        <div className="flex w-full justify-center items-center">
            <div className="flex h-24 lg:h-32 items-center justify-between lg:space-x-8 w-full lg:w-5/6 px-4">
                <div className="pr-2">
                    <NavLink exact to="/" >
                        <div className="cursor-pointer flex justify-center items-center">
                            <div className="w-20 h20">
                                <img className="object-contain" src={window.location.origin + "/logo.png"} alt="" />
                            </div>
                            <p className="text-gray-700 text-xl lg:text-2xl font-bold ml-2 mb-2">ATL YAPIM</p>
                        </div>
                    </NavLink>
                </div>
                <div 
                    onMouseLeave={() => setOpenWorks(false)}
                    className="hidden p-4 justify-center items-center space-x-2 lg:flex">
                    <NavLink
                        to="/works"
                        onClick={(e) => {
                            e.preventDefault()
                            setOpenWorks(!openWorks)
                        }}
                        onMouseEnter={() => setOpenWorks(true)}
                        className="p-2 hover:bg-atl_green rounded-md" activeClassName="bg-atl_green p-2 rounded-md">
                        <div className="font-bold text-md text-gray-600 text-center">WORKS</div>
                    </NavLink>
                    <NavLink
                        onMouseEnter={() => setOpenWorks(false)}
                        exact
                        to="/backstage"
                        className="p-2 hover:bg-atl_green rounded-md" activeClassName="bg-atl_green p-2 rounded-md">
                        <div className="font-bold text-md text-gray-600 text-center">BACKSTAGE</div>
                    </NavLink>
                    <NavLink
                        onMouseEnter={() => setOpenWorks(false)}
                        exact
                        to="/about"
                        className="p-2 hover:bg-atl_green rounded-md" activeClassName="bg-atl_green p-2 rounded-md">
                        <div className="font-bold text-md text-gray-600 text-center">ABOUT US</div>
                    </NavLink>
                    <NavLink
                        onMouseEnter={() => setOpenWorks(false)}
                        exact
                        to="/contact"
                        className="p-2 hover:bg-atl_green rounded-md" activeClassName="bg-atl_green p-2 rounded-md">
                        <div className="font-bold text-md text-gray-600 text-center">CONTACT</div>
                    </NavLink>

                    <Transition
                        show={openWorks}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="absolute top-24 w-40 border-1 bg-gray-400 border-gray-400 rounded-md transform -translate-x-3/4 p-2 overflow-hidden z-50">
                            <NavLink exact to="/works/tvc"
                                className="h-12 flex items-center justify-center rounded-md p-2 transition duration-500 ease-in-out hover:bg-gray-100 transform hover:-translate-y-1 cursor-pointer"
                                onClick={() => setOpenWorks(false)}>
                                <div className="text-semibold text-md">TVC</div>
                            </NavLink>
                            <NavLink exact to="/works/documentary"
                                className="h-12 flex items-center justify-center rounded-md p-2 transition duration-500 ease-in-out hover:bg-gray-100 transform hover:-translate-y-1 cursor-pointer"
                                onClick={() => setOpenWorks(false)}>
                                <div className="text-semibold text-md">DOCUMENTARY</div>
                            </NavLink>
                        </div>
                    </Transition>
                </div>
                <div className="hidden lg:flex lg:flex-col justify-center items-center">
                    <div className="">
                        <TextField
                            id="search"
                            label="Search"
                            variant="outlined"
                            value={search}
                            size="small"
                            sx={{ width: 200 }}
                            onChange={handleChange}
                            onKeyDown={onEnter}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search className="text-gray-300 hover:text-gray-500" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="flex">
                        <a href="https://vimeo.com/user156960506" target="_blank" rel="noreferrer">
                            <Tooltip title="Vimeo">
                                <IconButton >
                                    <Icon icon="mdi:vimeo" className="w-6 h-6 cursor-pointer fill-current text-gray-300 hover:text-gray-500" />
                                </IconButton>
                            </Tooltip>
                        </a>
                        <a href="https://www.youtube.com/channel/UC6rfOPabqlujxluxdctGU8Q" target="_blank" rel="noreferrer">
                            <Tooltip title="YouTube">
                                <IconButton >
                                    <YouTube className="w-8 h-8 cursor-pointer text-gray-300 hover:text-gray-500" />
                                </IconButton>
                            </Tooltip>
                        </a>
                        <a href="https://www.instagram.com/atl.yapim/" target="_blank" rel="noreferrer">
                            <Tooltip title="Instagram">
                                <IconButton >
                                    <Instagram className="w-8 h-8 cursor-pointer text-gray-300 hover:text-gray-500" />
                                </IconButton>
                            </Tooltip>
                        </a>
                        <a href="https://twitter.com/atlyapim2021" target="_blank" rel="noreferrer">
                            <Tooltip title="Twitter">
                                <IconButton >
                                    <Twitter className="w-8 h-8 cursor-pointer text-gray-300 hover:text-gray-500" />
                                </IconButton>
                            </Tooltip>
                        </a>
                    </div>
                </div>

                <div className="flex lg:hidden lg:mr-8">
                    <Tooltip title="Menu">
                        <IconButton onClick={() => setOpen(!open)}>
                            <Menu className="cursor-pointer" />
                        </IconButton>
                    </Tooltip>

                    <Transition
                        show={open}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="absolute top-20 w-40 border-1 bg-gray-400 border-gray-400 rounded-md transform -translate-x-3/4 p-2 overflow-hidden z-50">
                            <NavLink exact to="/works/tvc"
                                className="h-12  font-semibold flex items-center justify-center rounded-md p-2 transition duration-500 ease-in-out hover:bg-gray-100 transform hover:-translate-y-1 cursor-pointer"
                                onClick={() => setOpen(false)}>
                                <div className="text-semibold text-md">TVC</div>
                            </NavLink>
                            <NavLink exact to="/works/documentary"
                                className="h-12  font-semibold flex items-center justify-center rounded-md p-2 transition duration-500 ease-in-out hover:bg-gray-100 transform hover:-translate-y-1 cursor-pointer"
                                onClick={() => setOpen(false)}>
                                <div className="text-semibold text-md">DOCUMENTARY</div>
                            </NavLink>
                            <NavLink exact to="/backstage"
                                className="h-12 font-semibold flex items-center justify-center rounded-md p-2 transition duration-500 ease-in-out hover:bg-gray-100 transform hover:-translate-y-1 cursor-pointer"
                                onClick={() => setOpen(false)}>
                                <div className="text-semibold text-md">BACKSTAGE</div>
                            </NavLink>
                            <NavLink exact to="/about"
                                className="h-12 font-semibold flex items-center justify-center rounded-md p-2 transition duration-500 ease-in-out hover:bg-gray-100 transform hover:-translate-y-1 cursor-pointer"
                                onClick={() => setOpen(false)}>
                                <div className="text-semibold text-md">ABOUT US</div>
                            </NavLink>
                            <NavLink exact to="/contact"
                                className="h-12 font-semibold flex items-center justify-center rounded-md p-2 transition duration-500 ease-in-out hover:bg-gray-100 transform hover:-translate-y-1 cursor-pointer"
                                onClick={() => setOpen(false)}>
                                <div className="text-semibold text-md">CONTACT</div>
                            </NavLink>
                        </div>
                    </Transition>
                </div>
            </div>

        </div >
    )
}
