import React from 'react'
import { useParams } from 'react-router-dom'
import useWindowDimensions from 'hooks/useWindowDimensions';
import { MOVIE_DATA } from "util/data"

export default function Movie() {
    let { movieId } = useParams();
    const { height, width } = useWindowDimensions();
    const [columnCount, setColumnCount] = React.useState(2)
    const [movie, setMovie] = React.useState()

    React.useEffect(() => {
        if (width < 768) {
            setColumnCount(1)
        } else if (width < 1024) {
            setColumnCount(2)
        } else {
            setColumnCount(3)
        }
    }, [width])

    React.useEffect(() => {
        let filter = MOVIE_DATA.filter(m => m.movieId === movieId)
        setMovie(filter[0])
    }, [])

    let final1 = ""
    if (movie?.type === "backstage") {
        final1 = `${movie?.movieName}`
    } else if (movie?.type === "documentary") {
        final1 = `${movie?.movieName}`
    } else {
        final1 = `${movie?.client} - ${movie?.movieName}`
    }

    let final2 = ""
    if (movie?.type === "backstage") {
        final2 = ""
    } else if (movie?.type === "documentary") {
        final2 = ""
    } else {
        final2 = `${movie?.productionCompany}`
    }

    let final3 = ""
    if (movie?.type === "backstage") {
        final3 = ""
    } else if (movie?.type === "documentary") {
        final3 = ""
    } else {
        final3 = `${movie?.directorName}`
    }

    return (
        <div className="px-4 justify-center items-center">
            <div className="w-full justify-center items-center">
                <div className="aspect-w-16 aspect-h-9 lg:aspect-h-6">
                    <div className="flex w-full h-full justify-center items-center">
                        <iframe
                            width="100%"
                            height="100%"
                            src={movie?.movieLink}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                    </div>
                </div>
            </div>

            <div className="flex flex-col mt-4 space-y-4">
                <div className="font-bold text-xl">
                    {final1}
                </div>
                <div className="font-semibold text-lg">
                    {final2}
                </div>
                <div className="font-semibold text-lg">
                    {final3}
                </div>
            </div>
        </div>
    )
}
