const faker = require("faker");

const MIN_HEIGHT = 200;
const MAX_HEIGHT = 300;

const MOVIE_DATA = [
	{	
		type: "tvc",
		movieId: "finans-bank-babalar-gunu",
		client: "FİNANS BANK",
		movieName: "BABALAR GÜNÜ",
		productionCompany: "GRAM İSTANBUL",
		directorName: "",
		imageUrl: "https://i.ytimg.com/vi_webp/xxDEYVsy7KY/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/xxDEYVsy7KY?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "kuveyt-turk-bu-devirde-kuveyt-turklu-olmak-var",
		client: "KUVEYT TÜRK",
		movieName: "BU DEVİRDE KUVEYT TÜRKLÜ OLMAK VAR",
		productionCompany: "ATL YAPIM",
		directorName: "OĞUZ UYDU",
		imageUrl: "https://i.ytimg.com/vi_webp/QnMwQTisy5g/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/QnMwQTisy5g?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "neeksik-com",
		client: "neeksik.com",
		movieName: "neeksik.com",
		productionCompany: "ATL YAPIM",
		directorName: "GÖRKEM YARAR",
		imageUrl: "https://i.ytimg.com/vi_webp/PNRYTJGHl1Y/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/PNRYTJGHl1Y?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "eyg-insaat-adim-istanbul",
		client: "EYG İNŞAAT",
		movieName: "ADIM İSTANBUL",
		productionCompany: "GRAM İSTANBUL",
		directorName: "MERT UÇKAN",
		imageUrl: "https://i.ytimg.com/vi_webp/qulmsxen3GQ/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/qulmsxen3GQ?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "hurriyet-emlak-ev-dedigin-nedir",
		client: "HÜRRİYET EMLAK",
		movieName: "EV DEDİĞİN NEDİR?",
		productionCompany: "GRAM İSTANBUL",
		directorName: "İRFAN YILDIRIM",
		imageUrl: "https://i.ytimg.com/vi/Bm9EKQzj1g0/maxresdefault.jpg",
		movieLink: "https://www.youtube.com/embed/Bm9EKQzj1g0?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "yesilay-onlara-bir-gelecek-bagislayin",
		client: "YEŞİLAY",
		movieName: "ONLARA BİR GELECEK BAĞIŞLAYIN",
		productionCompany: "GENNA",
		directorName: "EMİR KHALILZADEH",
		imageUrl: "https://i.ytimg.com/vi_webp/HqC6DzDw_Xg/sddefault.webp",
		movieLink: "https://www.youtube.com/embed/HqC6DzDw_Xg?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "herbalife-degistir-hayatini",
		client: "HERBALIFE",
		movieName: "DEĞİŞTİR HAYATINI",
		productionCompany: "GRAM İSTANBUL",
		directorName: "OĞUZ UYDU",
		imageUrl: "https://i.ytimg.com/vi_webp/ldt0CLo321o/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/ldt0CLo321o?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "corega-mangal-keyfinin-tadini-cikarin",
		client: "COREGA",
		movieName: "MANGAL KEYFİNİN TADINI ÇIKARIN",
		productionCompany: "HEALTHY PEOPLE",
		directorName: "İRFAN YILDIRIM",
		imageUrl: "https://i.ytimg.com/vi_webp/L2GYi_FlxII/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/L2GYi_FlxII?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "corega-toz-yapistirici",
		client: "COREGA",
		movieName: "TOZ YAPIŞTIRICI",
		productionCompany: "HEALTHY PEOPLE",
		directorName: "İRFAN YILDIRIM",
		imageUrl: "https://i.ytimg.com/vi_webp/gH077_b9t9E/hqdefault.webp",
		movieLink: "https://www.youtube.com/embed/gH077_b9t9E?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "corega-temizleyici-tablet",
		client: "COREGA",
		movieName: "TEMİZLEYİCİ TABLET",
		productionCompany: "HEALTHY PEOPLE",
		directorName: "İRFAN YILDIRIM",
		imageUrl: "https://i.ytimg.com/vi_webp/FsfrbETTfdM/sddefault.webp",
		movieLink: "https://www.youtube.com/embed/FsfrbETTfdM?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "sateen-com-tikir-tikir-mode",
		client: "sateen.com",
		movieName: "TIKIR TIKIR MODA",
		productionCompany: "GENNA",
		directorName: "",
		imageUrl: "https://i.ytimg.com/vi_webp/W51lN4CxfIY/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/W51lN4CxfIY?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "bahcesehir-koleji-basarinin-siniri-yok",
		client: "BAHÇEŞEHİR KOLEJİ",
		movieName: "BAŞARININ SINIRI YOK",
		productionCompany: "",
		directorName: "İRFAN YILDIRIM",
		imageUrl: "https://i.ytimg.com/vi_webp/YaHUDinMBmE/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/YaHUDinMBmE?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "kuveyt-turk-ramazan",
		client: "KUVEYT TÜRK",
		movieName: "RAMAZAN",
		productionCompany: "GRAM İSTANBUL",
		directorName: "İRFAN YILDIRIM",
		imageUrl: "https://i.ytimg.com/vi_webp/UYfN5_R1v_U/sddefault.webp",
		movieLink: "https://www.youtube.com/embed/UYfN5_R1v_U?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "kuveyt-turk-ramazan-her-yere-gelir",
		client: "KUVEYT TÜRK",
		movieName: "RAMAZAN HER YERE GELİR",
		productionCompany: "GRAM İSTANBUL",
		directorName: "FIRAT MANÇUHAN",
		imageUrl: "https://i.ytimg.com/vi_webp/ftdNmwu-0Og/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/ftdNmwu-0Og?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "kuveyt-turk-saglamci-ol",
		client: "KUVEYT TÜRK",
		movieName: "SAĞLAMCI OL",
		productionCompany: "GRAM İSTANBUL",
		directorName: "LEONARDO DALESSANDRI",
		imageUrl: "https://i.ytimg.com/vi_webp/inha0QPMmQE/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/inha0QPMmQE?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "henkel-shamtu-coffee-ru",
		client: "HENKEL",
		movieName: "SHAMTU COFFEE (RU VERSION)",
		productionCompany: "",
		directorName: "BARIŞ BERBEROĞLU",
		imageUrl: "https://i.ytimg.com/vi_webp/aJYh1rqMhLs/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/aJYh1rqMhLs?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "henkel-blendax-bas-donduren-saclar",
		client: "HENKEL",
		movieName: "BLENDAX BAŞ DÖNDÜREN SAÇLAR",
		productionCompany: "",
		directorName: "BARIŞ BERBEROĞLU",
		imageUrl: "https://i.ytimg.com/vi_webp/BjZ5q6RcClw/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/BjZ5q6RcClw?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "iga-pass-seyahatinizin-en-keyifli-zamani",
		client: "İGA PASS",
		movieName: "SEYAHATİNİZİN EN KEYİFLİ ZAMANI",
		productionCompany: "M.A.R.K.A.",
		directorName: "AKSEL ÖZCAN",
		imageUrl: "https://i.ytimg.com/vi_webp/3qPo9Fy8-bU/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/3qPo9Fy8-bU?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "kultur-ve-turizm-bakanligi-home-of-poetry",
		client: "KÜLTÜR ve TURİZM BAKANLIĞI",
		movieName: "HOME of POETRY",
		productionCompany: "GENNA",
		directorName: "ŞENOL KORKMAZ",
		imageUrl: "https://i.ytimg.com/vi_webp/av3S69gFnTs/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/av3S69gFnTs?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "egepol-halka-arz-imaj",
		client: "EGEPOL",
		movieName: "HALKA ARZ İMAJ",
		productionCompany: "",
		directorName: "BARIŞ KEFELİ",
		imageUrl: "https://i.ytimg.com/vi_webp/icMNL7jqYMI/hqdefault.webp",
		movieLink: "https://www.youtube.com/embed/icMNL7jqYMI?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "kuveyt-turk-degerlerimizle-buyuyoruz",
		client: "KUVEYT TÜRK",
		movieName: "DEĞERLERİMİZLE BÜYÜYORUZ",
		productionCompany: "GRAM İSTANBUL",
		directorName: "ALİ KAAN DİNÇ",
		imageUrl: "https://i.ytimg.com/vi_webp/Iq95fb7e_lc/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/Iq95fb7e_lc?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "johnson-johnson-canped-candan-bakim",
		client: "JOHNSON & JOHNSON",
		movieName: "CANPED CANDAN BAKIM",
		productionCompany: "",
		directorName: "İRFAN YILDIRIM",
		imageUrl: "https://i.ytimg.com/vi_webp/VhROn1kZftQ/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/VhROn1kZftQ?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "kultur-ve-turizm-bakanligi-siir-siir-turkiye",
		client: "KÜLTÜR ve TURİZM BAKANLIĞI",
		movieName: "ŞİİR ŞİİR TÜRKİYE",
		productionCompany: "GENNA",
		directorName: "ŞENOL KORKMAZ",
		imageUrl: "https://i.ytimg.com/vi_webp/O9TaF8QeX18/hqdefault.webp",
		movieLink: "https://www.youtube.com/embed/O9TaF8QeX18?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "kuveyt-turk-finansman-urunleri",
		client: "KUVEYT TÜRK",
		movieName: "FİNANSMAN ÜRÜNLERİ",
		productionCompany: "",
		directorName: "İRFAN YILDIRIM",
		imageUrl: "https://i.ytimg.com/vi_webp/ZT1_UvrkhPQ/hqdefault.webp",
		movieLink: "https://www.youtube.com/embed/ZT1_UvrkhPQ?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "ziraat-katilim-usta-kalfa",
		client: "ZİRAAT KATILIM",
		movieName: "USTA KALFA",
		productionCompany: "HAPPY PEOPLE PROJECT",
		directorName: "HAZIM BAŞARAN",
		imageUrl: "https://i.ytimg.com/vi_webp/gyOQsKqCJQk/hqdefault.webp",
		movieLink: "https://www.youtube.com/embed/gyOQsKqCJQk?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "kuveyt-turk-altinlarim-elimin-altinda",
		client: "KUVEYT TÜRK",
		movieName: "ALTINLARIM ELİMİN ALTINDA",
		productionCompany: "GRAM İSTANBUL",
		directorName: "İRFAN YILDIRIM",
		imageUrl: "https://i.ytimg.com/vi_webp/N57EPtUHMCs/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/N57EPtUHMCs?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "finansbank-tartismadan-hakli-cikma-parametreleri",
		client: "FİNANSBANK",
		movieName: "TARTIŞMADAN HAKLI ÇIKMA PARAMETRELERİ",
		productionCompany: "",
		directorName: "İRFAN YILDIRIM",
		imageUrl: "https://i.ytimg.com/vi_webp/GIcyE6VGEFA/sddefault.webp",
		movieLink: "https://www.youtube.com/embed/GIcyE6VGEFA?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "tvc",
		movieId: "ie-menarini-sustenium-triple-action",
		client: "İE MENARINI",
		movieName: "SUSTENIUM TRIPLE ACTION",
		productionCompany: "GRAM İSTANBUL",
		directorName: "FIRAT MANÇUHAN",
		imageUrl: "https://i.ytimg.com/vi_webp/WRD1YkDkF6U/hqdefault.webp",
		movieLink: "https://www.youtube.com/embed/WRD1YkDkF6U?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "documentary",
		movieId: "karaman-lisani-belgesel",
		client: "",
		movieName: "KARAMAN LİSANI – BELGESEL",
		productionCompany: "",
		directorName: "",
		imageUrl: "https://i.ytimg.com/vi_webp/3py7NbzZV7I/hqdefault.webp",
		movieLink: "https://www.youtube.com/embed/3py7NbzZV7I?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "documentary",
		movieId: "iga-buyuk-goc-belgesel",
		client: "",
		movieName: "İGA BÜYÜK GÖÇ – BELGESEL",
		productionCompany: "",
		directorName: "",
		imageUrl: "https://i.ytimg.com/vi/X5SN1jMw13w/sddefault.jpg",
		movieLink: "https://www.youtube.com/embed/X5SN1jMw13w?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "backstage",
		movieId: "kuveyt-turk-bu-devirde-kuveyt-turklu-olmak-var-backstage",
		client: "KUVEYT TÜRK",
		movieName: "BU DEVİRDE KUVEYT TÜRKLÜ OLMAK VAR - BACKSTAGE",
		productionCompany: "ATL YAPIM",
		directorName: "OĞUZ UYDU",
		imageUrl: "https://i.ytimg.com/vi_webp/Qc65I2d2Tdw/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/Qc65I2d2Tdw?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "backstage",
		movieId: "adim-istanbul-backstage",
		client: "EYG İNŞAAT",
		movieName: "ADIM İSTANBUL - BACKSTAGE",
		productionCompany: "GRAM İSTANBUL",
		directorName: "MERT UÇKAN",
		imageUrl: "https://i.ytimg.com/vi_webp/dnFEvIE_kYM/maxresdefault.webp",
		movieLink: "https://www.youtube.com/embed/dnFEvIE_kYM?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "backstage",
		movieId: "sustenium-triple-action-backstage",
		client: "",
		movieName: "SUSTENIUM TRIPLE ACTION - BACKSTAGE",
		productionCompany: "",
		directorName: "",
		imageUrl: "https://i.ytimg.com/vi_webp/jPZTnJsK_XI/sddefault.webp",
		movieLink: "https://www.youtube.com/embed/jPZTnJsK_XI?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "backstage",
		movieId: "onlara-bir-gelecek-bagislayin-backstage",
		client: "",
		movieName: "ONLARA BİR GELECEK BAĞIŞLAYIN - BACKSTAGE",
		productionCompany: "",
		directorName: "",
		imageUrl: "https://i.ytimg.com/vi_webp/Gnguky1Zuwc/hqdefault.webp",
		movieLink: "https://www.youtube.com/embed/Gnguky1Zuwc?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "backstage",
		movieId: "usta-kalfa-backstage",
		client: "",
		movieName: "USTA KALFA - BACKSTAGE",
		productionCompany: "",
		directorName: "",
		imageUrl: "https://i.ytimg.com/vi_webp/q7nrQ7uL7Sk/default.webp",
		movieLink: "https://www.youtube.com/embed/q7nrQ7uL7Sk?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
	{	
		type: "backstage",
		movieId: "basarinin-siniri-yok-backstage",
		client: "",
		movieName: "BAŞARININ SINIRI YOK - BACKSTAGE",
		productionCompany: "",
		directorName: "",
		imageUrl: "https://i.ytimg.com/vi_webp/WFBRuPXlIoQ/sddefault.webp",
		movieLink: "https://www.youtube.com/embed/WFBRuPXlIoQ?rel=0",
		height: faker.datatype.number({ min: MIN_HEIGHT, max: MAX_HEIGHT }),
	},
]

export { MOVIE_DATA };
