import React from 'react'
import useWindowDimensions from 'hooks/useWindowDimensions';
import chunkArray from 'util/functions';
import Movie from './MovieCard';
import { MOVIE_DATA } from "util/data"

export default function TVC() {
    const { height, width } = useWindowDimensions();
    const [columnCount, setColumnCount] = React.useState(2)

    React.useEffect(() => {
        if (width < 768) {
            setColumnCount(1)
        } else if (width < 1024) {
            setColumnCount(2)
        } else {
            setColumnCount(3)
        }
    }, [width])

    let TVC_DATA = MOVIE_DATA.filter((m) => m.type === "tvc")
    let result = chunkArray(TVC_DATA, columnCount)

    return (
        <div className="flex flex-grow justify-center items-center">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 flex-1 px-4">
                {
                    result.map((data) => {
                        return (
                            <div className="flex flex-col flex-grow">
                                {
                                    data.map((movie) => {
                                        return (<Movie movie={movie} />)
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
