import React from 'react'
import { useHistory } from 'react-router-dom'

export default function MovieCard({ movie }) {
    const history = useHistory()
    
    let final1 = ""
    if (movie?.type === "backstage") {
        final1 = `${movie?.movieName}`
    } else if (movie?.type === "documentary") {
        final1 = `${movie?.movieName}`
    } else {
        final1 = `${movie?.client} - ${movie?.movieName}`
    }

    let final2 = ""
    if (movie?.type === "backstage") {
        final2 = ""
    } else if (movie?.type === "documentary") {
        final2 = ""
    } else {
        final2 = `${movie?.productionCompany}`
    }

    let final3 = ""
    if (movie?.type === "backstage") {
        final3 = ""
    } else if (movie?.type === "documentary") {
        final3 = ""
    } else {
        final3 = `${movie?.directorName}`
    }

    return (
        <div key={movie.name}
            onClick={() => {
                history.push(`/movie/${movie.movieId}`)
            }}
            style={{ height: movie.height }}
            className="flex flex-col m-2 justify-center items-center bg-gray-800 rounded-md cursor-pointer"
        >
            <div className="absolute flex flex-col w-64">
                <div className="text-white font-semibold text-md">
                    {final1}
                </div>
                <div className="text-white font-semibold text-md">
                    {final2}
                </div>
                <div className="mt-4 text-white font-bold text-md">
                    {final3}
                </div>
            </div>
            <div
                className="z-10 w-full h-full transition duration-500 ease-in-out hover:opacity-20"
            >
                <img
                    className="rounded-md object-cover w-full h-full"
                    src={movie.imageUrl}
                    alt="" />
            </div>
        </div>
    )
}
